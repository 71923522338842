<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Franchise</v-card-title>
        <v-alert
          v-for="(error, index) in errors"
          :key="index"
          type="error"
          variant="tonal"
          dense
          text
        >
          {{ error.join() }}
        </v-alert>
        <v-form class="pa-5 mt-5" ref="form" lazy-validation>
          <v-text-field
            label="Username"
            outlined
            :rules="[rule.required, rule.allowedCharacters]"
            :loading="usernameLoading"
            :error-messages="usernameError"
            prepend-icon="mdi-text"
            v-model="franchise.username"
            color="primary"
          ></v-text-field>
          <v-text-field
            label="Email"
            outlined
            :rules="[rule.required, rule.email]"
            prepend-icon="mdi-email-outline"
            v-model="franchise.email"
            color="primary"
          ></v-text-field>
          <v-text-field
            label="Password"
            outlined
            :rules="passwordRule"
            prepend-icon="mdi-lock-outline"
            v-model="franchise.password"
            color="primary"
          ></v-text-field>
          <v-col class="py-0">
            <v-switch
              class="mt-2"
              label="Sign up features"
              inset
              v-model="franchise.has_sign_up_features"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>
          <v-row class="mt-3" justify="end">
            <v-btn
              color="primary"
              :loading="getFranchisesLoading"
              :disabled="getFranchisesLoading"
              @click="save"
            >
              Save Changes
            </v-btn>
          </v-row>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { checkUsername } from "@/services/franchiseService";
import rules from "@/shared/rules";
import { debounce, find, get, omit } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Franchise",
  },
  data() {
    return {
      rule: rules,
      id: get(this.$route, "params.id"),
      loading: false,
      usernameLoading: false,
      usernameError: [],
      errors: {},
      franchise: {
        username: null,
        password: null,
        email: null,
        has_sign_up_features: 0,
      },
    };
  },

  async mounted() {
    if (!this.isCreate) {
      this.franchise = this.getFranchise;
    }
  },
  methods: {
    ...mapActions(["createFranchise", "updateFranchise"]),
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const result = this.isCreate
        ? await this.createFranchise(this.franchise)
        : await this.updateFranchise({
            id: this.id,
            payload: {
              ...this.franchise,
              _method: "patch",
            },
          });

      if (get(result, "error")) {
        this.errors = get(result, "error");

        return;
      }

      this.$store.dispatch("showSnackbar", result);
      this.$router.push("/app/franchise");
    },
    async validateUsername() {
      this.usernameLoading = true;

      const { data } = await checkUsername({
        id: this.id,
        username: get(this.franchise, "username"),
      });

      this.usernameError = data ? ["Username already exists."] : [];
      this.usernameLoading = false;
    },
  },
  computed: {
    ...mapGetters(["getFranchises", "getFranchisesLoading"]),
    isCreate() {
      return get(this.$route, "meta.kind") === "create";
    },
    getFranchise() {
      return {
        ...omit(find(this.getFranchises, { id: +this.id }), ["id"]),
      };
    },
    passwordRule() {
      return this.isCreate ? [rules.required] : [];
    },
  },
  watch: {
    "franchise.username": debounce(function () {
      this.validateUsername();
    }, 500),
  },
};
</script>
<style lang="scss" scoped></style>
